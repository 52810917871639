<template>
  <div class="d-flex container">
    <img style="width:150px" src="@/assets/nothing.png">
    <p>Sem Registros</p>

    <tabbar></tabbar>
  </div>
</template>


<script>
import tabbar from "@/components/Tabbar.vue";
export default {
  data(){
    return{

    }
  },
  components:{tabbar}
}
</script>

<style lang="scss" scoped>
.d-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 860px;
  flex-direction: column;
}
</style>
